<template>
  <v-navigation-drawer width="300" v-model="drawer_" app disable-resize-watcher>
    <v-list dense>
      <v-list-item link to="/home">
        <v-list-item-action>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>ホーム</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/reports">
        <v-list-item-action>
          <v-icon>mdi-file-download-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>レポート</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/qrCodes">
        <v-list-item-action>
          <v-icon>mdi-qrcode</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>QRコード</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link to="/signOut">
        <v-list-item-action>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>サインアウト</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    drawer_: {
      get () {
        return this.drawer
      },
      set () {
        // Ignored - Not sure why this gets called
      }
    }
  },
  methods: {
  }
}
</script>

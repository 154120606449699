<template>
  <div>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="enableSidebar" />
      <img height="40" width="40" src="@/assets/truffle_logo.png" />
      <v-toolbar-title
        class="headline font-weight-bold primary--text"
        v-if="isSuper"
        >Truffle AI Admin - Internal Use</v-toolbar-title
      >
      <v-toolbar-title class="headline font-weight-bold" v-else
        >Truffle AI Admin - Internal Use</v-toolbar-title
      >
    </v-app-bar>
    <Navigation :drawer="drawer" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Navigation from './NavigationDrawer'

export default {
  name: 'SharedToolbar',
  components: {
    Navigation
  },
  props: {
    enableSidebar: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(['admin']),
    isSuper () {
      if (this.admin) {
        return this.admin.type === 'super'
      } else {
        return false
      }
    }
  },
  data: () => ({
    drawer: false
  })
}
</script>
